import React from 'react';
import { Box, Container, Typography } from '@mui/material'
import Coop from '../../assets/images/coop.png';
// import Camion from '../../assets/images/graphiques-elements/camion.png';

const DeliverySection = ({ windowHeight, windowWidth }) => {
  // const [showTitle, setShowTitle] = useState(false);
  // const [distanceMove, setDistanceMove] = useState(0);
  // const triggerPosition = windowHeight * 1.2; // Animation starts
  // const stopPosition = windowHeight * 2.2; // Animation is completed (out of screen)

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollY = window.scrollY;
  //     if (scrollY > triggerPosition && scrollY < stopPosition) {
  //       const distanceFromTrigger = scrollY - triggerPosition;
  //       const maxDistance = (stopPosition - triggerPosition) * 0.4;
  //       const percentage = (distanceFromTrigger / maxDistance) * 100;
  //       if (percentage > 65) {
  //         setTimeout(() => {
  //           setShowTitle(true)
  //         }, 450);
  //       }
  //       if (percentage < 40) {
  //         setTimeout(() => {
  //           setShowTitle(false)
  //         }, 450);
  //       }
  //       setDistanceMove(percentage);
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [triggerPosition, stopPosition]);

  return (
    <Box sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: 10,
          paddingBottom: 10
        }}
      >
        <Box style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
          <Typography gutterBottom textAlign='center' variant='h2' color='primary.darker' sx={{ maxWidth: 550 }}>
            Fini la logistique <span style={{color: '#639C6E'}}>laissez nous le volant</span>
          </Typography>
          <Typography textAlign='center' variant='body1' color='primary.darker' sx={{ maxWidth: 550, fontWeight: "normal" }}>
            Livrez simplement le producteur référent de votre place de marché : on s'occupe du reste
          </Typography>
          <Box
            component='img'
            alt=''
            sx={{ width: { xs: '100%', md: '80%'}, mt: 5 }}
            src={Coop}
          />
        </Box>
        {/* <Box
          component='img'
          alt=''
          src={Camion}
          sx={{
            width: '55%',
            position: 'absolute',
            left: `${-95 + distanceMove}%`,
            zIndex: 1000,
            top: '0%',
            overflow: "visible",
            overflowInline: "visible",
            transition: 'left 1s ease',
          }}
        /> */}
      </Container>
    </Box>
  )
}

export default DeliverySection