import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import RestoreIcon from '@mui/icons-material/Restore';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import moment from 'moment'
import { Link } from 'react-router-dom';
import Category from '../D-chips/category';
import APIServices from '../../services/APIService';

const ArticleInFront = ({post}) => {
  const [image, setImage] = useState()

  useEffect(() => {
    const getImage = async () => {
      const respImage = await APIServices.getById('media', post?.featured_media)
      setImage(respImage.media_details.sizes)
    }

    getImage()
  }, [post])

  return (
    <Box
      sx={{
        backgroundImage: `url(${image?.full.source_url })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: `cover`,
        backgroundPosition: 'top center',
        borderRadius: 3
      }}
    >
      <Box
        component={Link} 
        to={`${post?.id}/${post?.slug}`} 
        sx={{ 
          display: 'block',
          textDecoration: 'none', 
          color: 'inherit',
          width: '100%', 
          height: '100%',
          bgcolor: 'rgba(0,0,0,0.4)',
          p: 5,
          borderRadius: 3,
          '&:hover': {
            bgcolor: 'rgba(0,0,0,0.3)'
          },
          '&:hover h1': {
            textDecoration: "underline"
          }
        }}
      >
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          {post?.categories.map((catId, index) => (
            <Category key={index} catId={catId} />
          ))}
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Category catId={post?.categories[0]} />
        </Box>
        <Typography variant="h1" sx={{ pt: 1, color: 'secondary.lighterest' }} dangerouslySetInnerHTML={{__html: post?.title.rendered}} />
        <Box sx={{display: 'flex', mt: 1, mb: 3 }}>
          <RestoreIcon sx={{ color: 'primary.lighter' }} />
          <Typography color="primary" sx={{ ml: 1, mr: 5, color: 'primary.lighter' }}>
            {moment(post?.date).format('DD/MM/YYYY')}
          </Typography>
          <HistoryEduIcon sx={{ color: 'primary.lighter', display: { xs: 'none', md: 'block' } }} />
          <Typography color="primary" sx={{ ml: 1, color: 'primary.lighter', display: { xs: 'none', md: 'block' } }}>{post?.yoast_head_json?.author}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ArticleInFront