import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  AppBar,
  Container,
  Toolbar,
  Slide,
  SwipeableDrawer,
  Typography,
  Grid
} from "@mui/material"
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import RozhoLogo from '../../assets/images/logos/logo-rozho-update.png';
import RozhoLogoBlog from '../../assets/images/logos/logo-blog.png';
import IconMenu from '../../assets/images/graphiques-elements/Iconmenu.png';
import ReactGA from 'react-ga';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const TRACKING_ID = "G-RMV1C5NMMY";
ReactGA.initialize(TRACKING_ID);

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Menu = (props) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const location = useLocation();
  if (location.pathname.match('/inscription') || location.pathname.match('/dashboard')) {
    return null;
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar sx={{ bgcolor: props.blog ? "primary.lighterest" : "secondary.lighter" }} elevation="0">
          <Container>
            <Toolbar disableGutters>
              <Box id="btn_return" sx={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }} component={Link} to={props.blog ? '/blog' : '/'}>
                <Box
                  component="img"
                  sx={{
                    width: props.blog ? 'auto' : 70,
                    height: props.blog ? 60 : 'auto',
                    mr: 2,
                    my: 2
                  }}
                  alt="Le super logo de Rozho"
                  src={props.blog ? RozhoLogoBlog : RozhoLogo}
                />
                {!props.blog && <Typography variant="h3" color="primary">Roz'ho</Typography>}
              </Box>
              {/* <a href="#benefitsSection">
                <Button
                  sx={{ mr: 1, minWidth: "fit-content" }}
                >
                  <Typography variant="body3" color="neutral.main" style={{ fontWeight: "bold" }}>
                    La solution
                  </Typography>
                </Button>
              </a>
              <a href="#offerSection">
                <Button
                  sx={{ mr: 1, minWidth: "fit-content" }}
                >
                  <Typography variant="body3" color="neutral.main" style={{ fontWeight: "bold" }}>
                    Nos tarifs
                  </Typography>
                </Button>
              </a>
              <a href="#faqSection">
                <Button
                  sx={{ mr: 1, minWidth: "fit-content" }}
                >
                  <Typography variant="body3" color="neutral.main" style={{ fontWeight: "bold" }}>
                    FAQ
                  </Typography>
                </Button>
              </a> */}
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'flex-end', minWidth: "fit-content" }}>
                {/*  <Button
                  component={Link}
                  to={`/blog`}
                  sx={{ ml: 3, mr: 2 }}
                >
                  <Typography variant="body3" color="secondary.dark" style={{ fontWeight: "bold" }}>
                    Je suis une coopérative
                  </Typography>
                </Button>
                <Button
                  component="a"
                  variant="contained"
                  href="https://rozho.app/connexion"
                  color="primary"
                  sx={{ borderRadius: 2, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, mt: { xs: 4, md: 0 } }}
                  size="large"
                >
                  Connexion
                </Button>
                */}
                <Button
                  variant="contained"
                  href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact"
                  component="a"
                  sx={{ mr: 3, minWidth: "fit-content" }}
                >
                  <Typography variant="body3" color="white" style={{ fontWeight: "bold" }}>
                    Nous contacter
                  </Typography>
                </Button>
                <Button
                  component="a"
                  variant="outlined"
                  href="https://rozho.app/connexion"
                  color="neutral"
                  sx={{ width: "fit-content", borderRadius: 2, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, mt: { xs: 4, md: 0 }, py: 0, pl: 0, pr: 1 }}
                  size="large"
                >
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item xs={2}>
                      <LocalPhoneIcon color="neutral" sx={{ mr: 2 }} />
                    </Grid>
                    <Grid item xs={7} >
                      <Typography variant="small2">Nous appeler</Typography>
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>0770079644</Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Box>

              {/* MOBILE PART */}
              <Box sx={{ flexGrow: 1, display: { xs: 'block', md: 'none' } }}></Box>
              <Box sx={{ cursor: 'pointer', display: { xs: 'block', md: 'none' } }} onClick={toggleDrawer(true)}>
                <Box
                  component="img"
                  sx={{
                    width: 25,
                    mr: 2,
                    my: 2
                  }}
                  alt="Le super logo de Rozho"
                  src={IconMenu}
                />
              </Box>

              <SwipeableDrawer
                anchor='right'
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <Box
                  sx={{
                    width: '70vw',
                    height: '100vh',
                    p: 2,
                    mt: 5
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', pb: 10 }}>
                    <Button
                      variant="outlined"
                      href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact"
                      color="primary"
                      sx={{ borderRadius: 2, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, mt: { xs: 4, md: 0 } }}
                      size=""
                    >
                      Nous contacter
                    </Button>
                    {/* <Button
                      component="a"
                      id="btn_connection_header"
                      className="auth btn_connection_header"
                      variant="outlined"
                      href="https://rozho.app/connexion"
                      color="primary"
                      sx={{ borderRadius: 5, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, mt: { xs: 4, md: 0 } }}
                      size="large"
                    >
                      Connexion
                    </Button> */}
                  </Box>
                </Box>
              </SwipeableDrawer>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>
  );
}

export default Menu;

