import createBreakpoints from "@mui/system/createTheme/createBreakpoints"
import { createTheme } from '@mui/material/styles';

const breakpoints = createBreakpoints({})
const font =  "'Gantari'";

export const Theme = createTheme({
  typography: {
    h1 : {
      fontFamily: font,
      fontWeight: 800,
      fontSize: 64,
      [breakpoints.down('md')]: {
        fontSize: 50,
      },
    },
    h2 : {
      fontFamily: font,
      fontWeight: 800,
      fontSize: 45,
      [breakpoints.down('md')]: {
        fontSize: 35,
      },
      lineHeight: '48px'
    },
    h3 : {
      fontFamily: font,
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '40px'
    },
    h4 : {
      fontFamily: font,
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '32px'
    },
    h5 : {
      fontFamily: font,
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '24px'
    },
    h6 : {
      fontFamily: font,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '20px'
    },
    subtitle1: {
      fontFamily: font,
      fontWeight: 700,
      fontSize: 22,
      lineHeight: '24px'
    },
    subtitle2: {
      fontFamily: font,
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '24px'
    },
    body1: {
      fontFamily: font,
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '24px'
    },
    body2: {
      fontFamily: font,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px'
    },
    body3: {
      fontFamily: font,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px'
    },
    button: {
      fontFamily: font,
      fontWeight: 800,
      lineHeight: '24px',
      textTransform: "none"
    },
    caption: {
      fontFamily: font,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px'
    },
    small1: {
      fontFamily: font,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px'
    },
    small2: {
      fontFamily: font,
      fontWeight: 700,
      fontSize: 10,
      lineHeight: '16px'
    },
    small3: {
      fontFamily: font,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '24px'
    },
    overline: {
      fontFamily: font,
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '16px'
    },
  },

  palette: {
    primary: {
      lighterest: '#F0F6F1',
      lighter: '#C1D8C5',
      light: '#92BA9A',
      main: '#639C6E',
      dark: '#456D4D',
      darker: '#273E2C',
      contrastText: '#fff',
    },
    secondary: {
      lighterest: '#FEFDFB',
      lighter: '#F9F4E7',
      light: '#EFE4C3',
      main: '#E0CD8E',
      dark: '#887B4E',
      darker: '#413B25',
      contrastText: '#000',
    },
    accent: {
      lighterest: '#F1A9AF',
      lighter: '#E76771',
      light: '#F08989',
      main: '#9C1923',
      dark: '#5A0E14',
      darker: '#180405',
      contrastText: '#fff',
    },
    info: { 
      light: '#D3E7FB',
      main: '#0B88B2',
      dark: '#1A2A34'
    },
    warning: { 
      light: '#FBF4EA',
      main: '#C28425',
      dark: '#563A10'
    },
    error: { 
      light: '#FEECED',
      main: '#E3262F',
      dark: '#5A0005'
    },
    success: { 
      light: '#E4F9F3',
      main: '#029E73',
      dark: '#03432A'
    },
    neutral: {
      white: '#ffffff',
      lighter: '#F6F4F4',
      light: '#E3DEDC',
      main: '#989290',
      dark: '#5D5856',
      darker: '#353231',
      black: '#201E1D',
      contrastText: '#fff',
    }
  },

  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: font,
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '16px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: ['none'],
          borderRadius: 8,
          gap: 8,
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 12,
          paddingBottom: 12,
          '&:hover': {
            boxShadow: ['none'],
          },
        },
        sizeLarge: {
          fontSize: 16,
          px: 24,
          py: 12,
        },
        sizeSmall: {
          fontSize: 12,
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 4,
          paddingBottom: 4
        },
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontFamily: font,
          fontWeight: 700,
          fontSize: 14,
          lineHeight: '20px'
        }
      }
    }
  },

});