import React, { Fragment, useEffect, useState } from 'react'
import { Box, Container, Divider, Pagination, Stack } from '@mui/material'
import APIServices from '../../services/APIService'
import BgGreen from '../../assets/images/graphiques-elements/blog-bg-green.jpg'
import ArticleInFront from '../../components/A-header/articleInFront'
import CardArticle from '../../components/C-cards/cardArticle'

const BlogMain = () => {
  const [posts, setPosts] = useState()
  const [inFrontPost, setInFrontPost] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [numberOfPages, setNumberOfPages] = useState(1)

  useEffect(() => {
    const getAllPosts = async () => {
      const response = await APIServices.getAll(`posts?page=${pageNumber}`, true)
      if (pageNumber === 1) setInFrontPost(response.data[0])
      setPosts(pageNumber === 1 ? response.data.slice(1) : response.data)
      setNumberOfPages(Number(response.headers['x-wp-totalpages']))
      window.scrollTo(0,pageNumber === 1 ? 0 : 400)
    }

    getAllPosts()
  }, [pageNumber])
  

  return ( (posts && inFrontPost) &&
    <>
      <Box
        sx={{ 
          backgroundImage: `url(${BgGreen})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: `100% 85%`,
          backgroundPosition: 'top center',
          pt: 2,
          pb: 5
        }}
      >
        <Container sx={{ pt: 5}}>
          <ArticleInFront post={inFrontPost} />
        </Container>
      </Box>
      <Container sx={{ pt: 5, pb: 15 }}>
        {posts?.map((post, index) => (
          <Fragment key={index}>
            <CardArticle post={post} />
            <Divider sx={{ borderColor: "secondary.light", mt: { xs: 6, md: 4 }, mb: { xs: 8, md: 6 } }} />
          </Fragment>
        ))}
        <Stack alignItems="center">
          <Pagination 
            count={numberOfPages} 
            color="primary" 
            shape="rounded" 
            size="large" 
            onChange={(e, value) => setPageNumber(value)} 
          />
        </Stack>
      </Container>
    </>
  )
}

export default BlogMain