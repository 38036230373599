import React, { useEffect, useState } from "react"
import { Chip } from "@mui/material"
import APIServices from "../../services/APIService"
import { Link } from "react-router-dom"

const Category = ({catId, ...props}) => {
  const [category, setCategory] = useState()

  useEffect(() => {
    const getCategory = async () => {
      const response = await APIServices.getById('categories', catId)
      setCategory(response)
    }

    getCategory()
  }, [catId])

  return  <Chip 
            {...props}
            label={category?.name} 
            sx={{ 
              mr: 1, 
              bgcolor: category?.acf.color, 
              color: 'white', 
              '&:hover': { cursor: 'pointer' } 
            }} 
            component={Link} 
            to={`categories/${category?.id}/${category?.slug}`} 
          />
}

export default Category