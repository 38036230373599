import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Menu from '../../components/A-header/appBar'
import Footer from '../../components/F-footer/footer'
import { Box } from '@mui/material'

const Blog = ({languages}) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  

  return (
    <>
      <Menu blog />
      <Box sx={{ minHeight: '80vh' }}>
        <Outlet />
      </Box>
      <Footer blog language={languages.bigTitle} />
    </>
  )
}

export default Blog