import React from 'react'
import { Navigate, Route, Routes } from "react-router-dom";
import MainPage from "./views/home"
import Languages from './assets/elements/siteLanguage'
import ReactGA from 'react-ga';
import Blog from './views/blog';
import SingleArticle from './views/blog/singleArticle';
import BlogMain from './views/blog/blogMain';
import CategoryArticles from './views/blog/categoryArticles';
  const TRACKING_ID = "G-EF2P74CMHQ";
  ReactGA.initialize(TRACKING_ID);

const App = () => {

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />}/>
      <Route path="/home" element={<MainPage languages={Languages} />}/>
      <Route path="/blog" element={<Blog languages={Languages} />}>
        <Route path="" element={<BlogMain />}/>
        <Route path=":id/:slug" element={<SingleArticle />}/>
        <Route path="categories/:id/:slug" element={<CategoryArticles />}/>
      </Route>
    </Routes>
  );
}
 
export default App;


