import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import APIServices from '../../services/APIService'
import RestoreIcon from '@mui/icons-material/Restore';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Category from '../D-chips/category';

const CardArticle = ({post}) => {
  const [image, setImage] = useState()

  const returnExcerpt = (text) => {
    let findIndex = text?.indexOf('<a class="more-link"')
    return text?.slice(0, findIndex) + '<a style="text-decoration:none;color:#639C6E" href="">lire la suite</a>'
  } 

  useEffect(() => {
    const getImage = async () => {
      if (post.featured_media) {
        const respImage = await APIServices.getById('media', post?.featured_media)
        setImage(respImage.media_details ? respImage.media_details.sizes : respImage.guid.rendered)
      }
    }
    getImage()
  }, [post])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4} sx={{ overflow: 'hidden' }}>
        <Box 
          component="img"
          src={image?.medium ? image?.medium.source_url : image?.full.source_url }
          sx={{ width: '100%', borderRadius: 3, maxHeight: 320, objectFit: 'cover' }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        {post.categories.map((catId, index) => (
          <Category key={index} catId={catId} size="small" />
        ))}
        <Box 
          component={Link} 
          to={`/blog/${post?.id}/${post.slug}`} 
          sx={{ 
            textDecoration: 'none', 
            color: 'inherit',
            '&:hover h2': {
              textDecoration: "underline"
            }
          }}
        >
          <Typography variant="h2" dangerouslySetInnerHTML={{__html: post?.title.rendered}} />
          <Box sx={{display: 'flex', mt: 1, mb: 3 }}>
            <RestoreIcon color="primary" fontSize="small" />
            <Typography variant="body2" color="primary" sx={{ ml: 1, mr: 5 }}>
              {moment(post?.date).format('DD/MM/YYYY')}
            </Typography>
            <HistoryEduIcon color="primary" fontSize="small" />
            <Typography variant="body2" color="primary" sx={{ ml: 1 }}>{post?.yoast_head_json?.author}</Typography>
          </Box>
          <Typography variant="body3" dangerouslySetInnerHTML={{__html: returnExcerpt(post?.excerpt.rendered)}} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default CardArticle