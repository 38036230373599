import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const StatsSection = ({ windowHeight, windowWidth }) => {

  return (
    <Box sx={{ bgcolor: "neutral.white" }}>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'row' },
          alignItems: { xs: 'flex-start', md: 'center' },
          overflow: "visible",
          overflowInline: "visible",
          paddingTop: 10,
          paddingBottom: 10
        }}
      >
        <Box sx={{ flexGrow: 1, mr: { xs: 0, md: 20 } }}>
          <Typography variant='h2' color='primary.main'>
            Augmenter votre chiffre d’affaires
          </Typography>
          <Typography variant='body1' color='primary.darker' sx={{ fontWeight: "normal", marginTop: 1 }}>
            Bénéficiez d’une faible commission sur vos transactions, frais bancaires inclus.
          </Typography>
        </Box>
        <Typography 
          component='h3'
          variant='h1' 
          color='primary.main' 
          sx={{ fontWeight: "bold", fontSize: 200 }}>
          8%
        </Typography>
      </Container>
    </Box>

  )
}

export default StatsSection;