import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import Computer from '../../assets/images/graphiques-elements/computergroup.png';

const InFrontSlider = () => {

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        bgcolor: "#F9F4E7",
        display: 'flex',
        height: 'calc(100vh - 66px)',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: { xs: 'column', md: 'row' }
      }}
    >
      <Box sx={{maxWidth: 500}}>
        <Typography variant='h1' color='primary.darker'>
          L’outil de vente en circuit court pour <span style={{color: '#639C6E'}}>l’autonomie des producteurs</span>
        </Typography>
        <Button href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteTest" variant="contained" sx={{ mt: 5 }}>
          Je m'inscris à l'essai gratuit
        </Button>
      </Box>
      <Box component='img' alt='' sx={{ width: { xs: '90%', md: '100%'}, mt: { xs: 3, md: 0}}} src={Computer} />
    </Container>
  )
}

export default InFrontSlider