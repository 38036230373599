import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Container, Typography, Button } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const FAQSection = () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  const questions = [
    { id: 1, title: "Comment fonctionne la livraison ?", response: "Chaque place de marché a un producteur référent chez qui vous déposer les colis à livrer. A partir de là nous collectons ce producteur et livrons vos clients." },
    { id: 2, title: "Combien coûte la livraison ?", response: "Grâce à la massification nous pouvons réduire considérablement sont coût. Elle sera donc payé 90% par l'acheteur et 10% par la place de marché, donc vous." },
    { id: 3, title: "Combien coûte Roz'ho ?", response: "Nous prennons un pourcentage sur transaction. Si vous ne faites pas de vente vous ne payez rien !" },
    { id: 4, title: "Que se passe-t-il si quelque chose est en rupture de stock et que je dois donner des instructions spécifiques ?", response: "Si vous utilisez un logiciel de gestion de stock directement relié à Roz'ho vous n'aurez rien à faire, sinon écrivez-nous !" },
    { id: 5, title: "Que se passe-t-il s'il y a un problème avec ma commande ?", response: "Tout dépend du problème : s'il est en amont nous vous mettons en relation avec l'acheteur, si le problème est sur la livraison nous gérons nous même le SAV." },
    { id: 6, title: "Comment fonctionne le service client ?", response: "Nous sommes à votre écoute pour toutes questions. Vous pouvez nous envoyer un message ou même nous appeller directement." }
  ]

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        msOverflowX: 'hidden',
        overflowInline: 'hidden',
        overflowBlock: 'hidden',
        paddingTop: 10,
        paddingBottom: 5
      }}
    >
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 5, marginTop: "1%" }}>Questions fréquentes</Typography>
      {questions.map((question) => (
        <div style={{ width: { xs: '100%', md: '80%'}, alignSelf: "center" }}>
          <Accordion
            expanded={expanded === question.id}
            onChange={handleChange(question.id)}
            sx={{
              width: "100%",
              backgroundColor: "transparent",
              boxShadow: "none",
              borderBottom: "1px solid #E7E5E4",
              borderRadius: 0,
              margin: "0 auto",
              "&.Mui-expanded": {
                borderBottom: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={expanded === question.id ? <RemoveIcon /> : <AddIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1">{question.title}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                width: "100%",
                margin: "0 auto",
                marginTop: 0,
                marginBottom: 0
              }}
            >
              <Typography>{question.response}</Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
      <Button href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteTest" type="submit" variant="contained" sx={{ mt: 5, width: "fit-content", alignSelf: "center" }}>
        Tester Roz'ho gratuitement
      </Button>
    </Container>
  )
}

export default FAQSection