const bigTitle = {
  homeTitle: 'La plateforme des produits ultra locaux pour les pros',
  homeTitleMobile: 'L\'approvision-<br/>nement en circuit court pour les pros du secteur alimentaire',
  middleTitle: 'Chez Roz\'ho, nous nous engageons à la juste rémunération des producteurs',
  beginTitle: 'Lancement le 15 octobre',
  targetTitle: 'Lancement le 11 octobre',
  newsletterButton: 'S\'inscrire à la newsletter',
  footerTitle: 'Le réseau autrement',
  targetButton: 'Je teste Roz\'ho gratuitement',
  footerMenu: [
    {name: 'Conditions générales de ventes', link: 'CGV'},
    {name: 'Politique de confidentialité', link: 'CONF'}
  ],
  credits: [
    '@2022 ROZ\'HO',
    'Droits réservés'
  ]
}

const team = {
  teamTitle: 'Une équipe proche de vous',
  julieTitle: 'Elle<br/>c\'est<br/>Julie',
  julieTxt: 'La restauration est déjà dans sa famille alors Julie ne rate jamais une bonne occasion de découvrir de nouvelles cuisines et en profite (toujours) pour faire aussi de belles rencontres ! Julie est prête à tout bousculer pour vivre des aventures improbables, c’est donc sans hésiter qu’elle rejoint Roz’ho pour tenter de révolutionner ce secteur qu’elle chérie.',
  remyTitle: 'Lui,<br/>c\'est<br/>Rémy',
  remyTxt: 'De sa campagne natale entourée de petits producteurs à Paris Rémy a perdu la facilité des produits de proximité et de qualité. Il se mit donc en quête d’une nourriture plus saine qui le mena, de fil en aiguille, à la création de Roz’ho et au développement de ce projet hors normes.',
}

const exportedObject = {
  bigTitle,
  team
};

export default exportedObject