import React, { useState, useEffect } from 'react';
import Menu from '../../components/A-header/appBar';
import Footer from '../../components/F-footer/footer';
import InFrontSlider from '../../components/B-slider/inFrontSlider';
import BenefitsSection from '../../components/E-sections/BenefitsSection';
//import InterfaceSection from '../../components/E-sections/InterfaceSection';
import StatsSection from '../../components/E-sections/StatsSection';
import DeliverySection from '../../components/E-sections/DeliverySection';
import FAQSection from '../../components/E-sections/FAQSection';

const MainPage = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#F9F4E7" }}>
      <Menu />
      <InFrontSlider windowHeight={windowHeight} windowWidth={windowWidth} style={{ marginTop: 0 }} />
      <BenefitsSection windowWidth={windowWidth} style={{
        paddingLeft: 0, paddingRight: 0, marginTop: 0
      }} />
      <DeliverySection windowWidth={windowWidth} style={{
        paddingLeft: 0, paddingRight: 0, marginTop: 0
      }} />
      {/* <InterfaceSection  windowHeight={windowHeight} windowWidth={windowWidth} style={{
          paddingLeft: 0, paddingRight: 0
        }} /> */}
      <StatsSection windowHeight={windowHeight} windowWidth={windowWidth}/>
      <FAQSection windowHeight={windowHeight} windowWidth={windowWidth} style={{ marginTop: 0 }} />
      <Footer windowHeight={windowHeight} windowWidth={windowWidth} style={{ marginTop: 0 }} />
    </div>
  );
}

export default MainPage;