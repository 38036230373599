import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import APIServices from '../../services/APIService'
import BgGreen from '../../assets/images/graphiques-elements/blog-bg-green.jpg'
import RestoreIcon from '@mui/icons-material/Restore';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom'
import useScrollTrigger from '@mui/material/useScrollTrigger';
import moment from 'moment'
import Category from '../../components/D-chips/category'
import CardRelatedArticle from '../../components/C-cards/cardRelatedArticle'
import Roseaux from '../../assets/images/graphiques-elements/roseaux.png'

// const Tag = ({tagId}) => {
//   const [tag, setTag] = useState()

//   useEffect(() => {
//     const gatTag = async () => {
//       const response = await APIServices.getById('tags', tagId)
//       setTag(response)
//     }

//     gatTag()
//   }, [tagId])

//   return <Typography color="seondary">#{tag?.name}</Typography>
// }


const SingleArticle = (props) => {
  const {id} = useParams()
  const [article, setArticle] = useState()
  const [relatedArticles, setRelatedArticles] = useState()
  const [image, setImage] = useState()
  const { window } = props;
  const elementRef = useRef(null);
  const [titleHeight, setTitleHeight] = useState(100)

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  useEffect(() => {
    const getArticle = async () => {
      const response = await APIServices.getById('posts', id)
      setArticle(response)

      const respImage = await APIServices.getById('media', response.featured_media)
      setImage(respImage.media_details.sizes)

      const relatedPosts = await APIServices.getRelatedPosts(id)
      setRelatedArticles(relatedPosts)
    }

    getArticle()
  }, [id])

  useEffect(() => {
    setTitleHeight(elementRef.current.clientHeight);
  }, [article])

  // CUT INGREDIENTS TO 2
  function splitArrayInHalf(arr) {
    const mid = Math.ceil(arr.length / 2);
    const firstHalf = arr.slice(0, mid);
    const secondHalf = arr.slice(mid);
    
    return [firstHalf, secondHalf];
  }

  return (
    <Box
      sx={{ 
        backgroundImage: `url(${BgGreen})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: `100% ${165 + titleHeight}px`,
        backgroundPosition: 'top center',
        pt: 2,
        mb: -10
      }}
    >
      <Container sx={{ pt: 2, position: 'relative', top: 35, zIndex: 1000 }}>
        <Button 
          component={Link}
          to={'/blog'}
          variant='text' 
          sx={{ color: 'neutral.dark', pl: 1 }} 
          startIcon={<ArrowBackIosIcon />}
        >
          Retour
        </Button>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} xl={5}>
            <Box sx={{ position: 'sticky', top: trigger ? 20 : 157, bottom: 500, transition: 'top .4s' }}>
              <Box>
                <Box 
                  component="img"
                  src={image?.full.source_url}
                  sx={{ width: '100%', borderRadius: 3 }}
                />
                {article?.acf.recipe && 
                  <Box sx={{ bgcolor: 'primary.lighterest', borderRadius: 3, p: 2, mt: 2 }}>
                    <Box sx={{ display: 'flex' }}>
                      <Typography variant="h5" sx={{ color: 'primary.dark', mr: 1 }}>Temps de préparation :</Typography>
                      <Typography component="span" sx={{ color: 'primary.darker' }}>{article?.acf.preparation_time}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                      <Typography variant="h5" sx={{ color: 'primary.dark', mr: 1 }}>Difficulté :</Typography>
                      {[1,2,3,4,5].map((index) => (
                        <Box 
                          component="img"
                          src={Roseaux}
                          sx={{ width: '25px', borderRadius: 3, mr: 1, opacity: article?.acf.complexity >= index ? 1 : 0.4 }}
                        />
                      ))}
                    </Box>
                  </Box>
                }
                {/* {greaterThanMid && article?.tags.map((tag, index) => (
                  <Tag key={index} tagId={tag} />
                ))} */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} xl={7}>
            {article?.categories.map((cat, index) => (
              <Category key={index} catId={cat} />
            ))}
            <Typography component="h1" variant="h1" ref={elementRef} dangerouslySetInnerHTML={{__html: article?.title.rendered}} />
            <Box sx={{display: 'flex', mt: 3, mb: 5 }}>
              <RestoreIcon color="primary" />
              <Typography color="primary" sx={{ ml: 1, mr: 5 }}>
                {moment(article?.date).format('DD/MM/YYYY')}
              </Typography>
              <HistoryEduIcon color="primary" />
              <Typography color="primary" sx={{ ml: 1 }}>{article?.yoast_head_json?.author}</Typography>
            </Box>
            {article?.acf.recipe &&
              <>
                <Typography variant="body2" sx={{ mb: 3 }}>{article?.acf.subtitle}</Typography>

                <Typography variant="h3" sx={{ mb: 2, color: 'primary.dark' }}>Ingrédients</Typography>
                <Grid container spacing={1}>
                  <Grid xs={12} md={6} item>
                    <ul>
                      {splitArrayInHalf(article?.acf.ingredients)[0].map((ing, index) => (
                        <li key={index}>
                          {ing.quantity && <Typography component="span" variant="body2" sx={{ mr: 1 }}><b>{ing.quantity}</b></Typography>}
                          <Typography component="span" variant="body2">{ing.ingredient}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <ul>
                      {splitArrayInHalf(article?.acf.ingredients)[1].map((ing, index) => (
                        <li key={index}>
                          {ing.quantity && <Typography component="span" variant="body2" sx={{ mr: 1 }}><b>{ing.quantity}</b></Typography>}
                          <Typography component="span" variant="body2">{ing.ingredient}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                </Grid>

                <Typography variant="h3" sx={{ mt: 6, color: 'primary.dark' }}>Étapes</Typography>
              </>
            }
            <Typography variant="body1" dangerouslySetInnerHTML={{__html: article?.content.rendered}} />
          </Grid>


          <Grid item xs={12} sx={{ mt: 12 }}>
            <Typography variant="h4">Plus d'articles</Typography>
          </Grid>
          {relatedArticles?.map((post, index) => (
            <Grid key={index} item xs={12} md={6}>
              <CardRelatedArticle post={post} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default SingleArticle