import React from "react";
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import RozhoLogo from '../../assets/images/logos/logo-rozho-update.png';
import RozhoTitle from '../../assets/images/rozho.png';
import { Container } from '@mui/system';

const Footer = ({ windowHeight, windowWidth }) => {
  const location = useLocation();
  if (location.pathname.match('/inscription') || location.pathname.match('/dashboard')) {
    return null;
  }

  return (
    <footer>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: "#273E2C",
          display: 'flex',
        }}
      >
        <Container
          maxWidth={windowWidth}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingY: 2,
          }}
        >
            <img
              src={RozhoLogo}
              alt="Le super logo de Rozho"
              style={{ width: '140px', marginBottom: '10px', marginLeft: 50 }}
            />
            <img
              src={RozhoTitle}
              alt="Roz'ho"
              style={{ width: '200px', marginBottom: '10px', marginLeft: 50 }}
            />
            <Typography variant="body3" color="neutral.lighter" sx={{ marginBottom: 1, marginLeft: 6 }}>
              Société unipersonnelle au capital de 1000€ située<br/>au 40 rue Alexandre Dumas 75011 Paris
            </Typography>
        </Container>
      </Box>
    </footer>
  )
}

export default Footer;