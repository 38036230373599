import React, { Fragment, useEffect, useState } from 'react'
import { Box, Button, Container, Typography, Divider } from '@mui/material'
import { useParams, Link } from 'react-router-dom'
import CardArticle from '../../components/C-cards/cardArticle'
import APIServices from '../../services/APIService'
import BgGreen from '../../assets/images/graphiques-elements/blog-bg-green.jpg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const CategoryArticles = () => {
  const { id, slug } = useParams()
  const [postsCat, setPostsCat] = useState()

  useEffect(() => {
    const getAllPosts = async () => {
      const response = await APIServices.getAll(`posts?categories=${id}`)
      setPostsCat(response)
    }

    getAllPosts()
  }, [id])

  return (
    <>
      <Box
        sx={{ 
          backgroundImage: `url(${BgGreen})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: `100% 100%`,
          backgroundPosition: 'top center',
          pt: 2
        }}
      >
        <Container sx={{ pt: 7, pb: 4 }}>
          <Button 
            component={Link}
            to={'/blog'}
            variant='text' 
            sx={{ color: 'neutral.dark', pl: 1 }} 
            startIcon={<ArrowBackIosIcon />}
          >
            Retour
          </Button>
          <Typography variant="h2">{slug.charAt(0).toUpperCase() + slug.slice(1)}</Typography>
        </Container>
      </Box>
      <Container sx={{ pt: 5, pb: 15 }}>
        {postsCat?.map((post, index) => (
          <Fragment key={index}>
            <CardArticle post={post} />
            <Divider sx={{ borderColor: "secondary.light", mt: 4, mb: 6 }} />
          </Fragment>
        ))}
      </Container>
    </>
  )
}

export default CategoryArticles