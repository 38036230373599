import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import MoneyGrowth from '../../assets/images/new-images/moneyGrowth.png';
import ComputerClients from '../../assets/images/new-images/Computer-clients.png';
import OwnTime from '../../assets/images/new-images/OwnTime.png';

const BenefitsSection = ({ windowHeight, windowWidth }) => {
  return (
    <Box sx={{ bgcolor: "secondary.light", }}>
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: 10,
          paddingBottom: 10
      }}>

        <Grid container spacing={2}>
          <Grid item xs={2} md={2} lg={3}></Grid>
          <Grid item xs={12} md={8} lg={6}>
            <Typography gutterBottom textAlign="center" component="h3" variant='h2' color='secondary.darker'>
              Rassemblez vos moyens pour élargir votre offre
            </Typography>
            <Typography textAlign="center" variant='body1' color='secondary.darker' sx={{ mb: 4 }}>
              Réunissez-vous sur une place de marché locale en ligne à destination de vos clients professionnels
            </Typography>
          </Grid>
          <Grid item xs={2} md={2} lg={3}></Grid>

          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}>
              <Box component='img' alt='' sx={{ width: { xs: '30%', md: '50%'} }} src={OwnTime} />
              <Typography variant="subtitle1" color="secondary.darker" sx={{mb: { xs: 8, md: 0 }, textAlign: "center", mx: 4, mt: 3}}>
                Gagnez du temps sur la commercialisation
              </Typography>
          </Grid>

          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Box component='img' alt='' sx={{ width: { xs: '50%', md: '90%'} }} src={ComputerClients} />
            <Typography variant="subtitle1" color="secondary.darker" sx={{mb: { xs: 8, md: 0 }, textAlign: "center", mx: 4, mt: 3}}>
              Mutualisez vos clients et élargissez votre audience
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Box component='img' alt='' sx={{ width: { xs: '30%', md: '50%'} }} src={MoneyGrowth} />
            <Typography variant="subtitle1" color="secondary.darker" sx={{textAlign: "center", mx: 4, mt: 3}}>
              Augmentez votre chiffre d’affaire
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default BenefitsSection;