import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import APIServices from '../../services/APIService'
import RestoreIcon from '@mui/icons-material/Restore';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import moment from 'moment';
import { Link } from 'react-router-dom';

const CardRelatedArticle = ({post}) => {
  const [image, setImage] = useState()

  useEffect(() => {
    const getImage = async () => {
      const respImage = await APIServices.getById('media', post?.featured_media)
      setImage(respImage.media_details.sizes)
    }

    getImage()
  }, [post])

  return (
    <Box 
      component={Link} 
      to={`/blog/${post?.id}/${post.slug}`} 
      sx={{ 
        textDecoration: 'none', 
        color: 'inherit', 
        height: '100%',
      }}
    >
      <Grid 
        container 
        sx={{ 
          borderRadius: 3, 
          overflow: 'hidden', 
          bgcolor: 'secondary.light', 
          height: '100%',
          '&:hover': {
            bgcolor: 'secondary.main'
          }
        }}
      >
        <Grid item xs={12} md={5}>
          <Box
            sx={{ 
              width: '100%', 
              height: { xs: 200, md: '100%' },
              backgroundImage: `url(${image?.medium ? image?.medium.source_url : image?.full.source_url })`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: `cover`,
              backgroundPosition: 'top center',
            }}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', pl: 2, py: 2, pr: 1 }}>
            <Typography variant="h5" dangerouslySetInnerHTML={{__html: post?.title.rendered}}></Typography>
            <Box sx={{display: 'flex', my: 2 }}>
              <RestoreIcon fontSize="small" color="primary" />
              <Typography variant="small3" color="primary" sx={{ ml: 1, mr: 2 }}>
                {moment(post?.date).format('DD/MM/YYYY')}
              </Typography>
              <HistoryEduIcon fontSize="small" color="primary" />
              <Typography variant="small3" color="primary" sx={{ ml: 1 }}>{post?.yoast_head_json?.author}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CardRelatedArticle